<template>
  <HeaderComponent />

  <!-- Composant Home -->
  <HomeComponent v-if="selectedText === 'Home'" />

  <!-- Conteneur des cartes -->
<div class="cards-container">
  <CardComponent
    v-for="(card, index) in cards"
    :key="index"
    :title="card.title"
    :description="card.description"
    :bottom="card.bottom"
    :icon="card.icon"
    class="card"
  />
</div>

  <!-- Timeline et SkillsComponent sont maintenant toujours visibles -->
  <div class="timeline-container">
    <TimelineComponent :events="timelineEvents" />
  </div>
  <SkillsComponent />
</template>


<script>

import CardComponent from './components/CardComponent.vue';
import HeaderComponent from './components/HeaderComponent.vue';
import HomeComponent from './components/HomeComponent.vue';
import TimelineComponent from './components/TimelineComponent.vue';
import SkillsComponent from './components/SkillsComponent.vue';

import cardsData from '@/cards.json';
export default {
  name: 'App',
  components: {
    TimelineComponent,
    HeaderComponent,
    HomeComponent,
    CardComponent,
    SkillsComponent
  },
  data() {
    return {
      selectedText: 'Home',
      cards: cardsData,
      timelineEvents: [
        { title: 'Internship as mobile developer', description: 'Assistance in developing a health mobile application. Languages ​​used: Native Script and VueJS.', date: 'July 2020' },
        { title: 'IA Racing', description: 'Built and ran autonomous cars. Technologies used Raspberry PI, Linux, Python.', date: 'January 2021' },
        { title: 'Internship as IOT student', description: 'Discover IOT topics, introduction to programming microcontroller. Understanding of datasheets.', date: 'Summer 2021' },
        { title: 'Internship as web developer', description: 'Built a full-stack website, Angular and Python.', date: '2022-2023' },
        { title: 'Web consultant', description: 'Working at ALTEN Belgium as consultant in several companies like Macq or GSK. Main skill used: Angular, Scala and Agile methodology.', date: '2022-2023' }
      ]
    };
  }
};
</script>

<style>
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 100vw;
  overflow-x: hidden; /* Empêche tout débordement horizontal */
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit; /* Applique le box-sizing à tous les éléments */
}

@font-face {
  font-family: 'Poppins';
  src: url('@/assets/fonts/Poppins.ttf') format('truetype');
}

@font-face {
  font-family: 'classic_robot';
  src: url('@/assets/fonts/classic_robot.otf') format('opentype');
}

h1, h2, p,h3 {
  font-family: 'Poppins';
}

.dark {
  color: #999999;
  background: #16171d;
}

.light {
  color: #2e2e2e;
  background: #f0f0f0;
}

.cards-container {
  margin-top: 100px;
  display: flex;
  flex-wrap: wrap; /* Permet de passer à la ligne si nécessaire */
  justify-content: center;
  gap: 20px;
  padding: 20px;
  max-width: 100%; /* Empêche le conteneur d'excéder la largeur de l'écran */
  box-sizing: border-box; /* Assure que le padding ne provoque pas de débordement */
}

.card {
  flex: 1 1 300px;
  max-width: 400px;
  width: 100%; /* Les cartes occupent toujours 100% de leur conteneur */
  box-sizing: border-box;
}

.timeline-container {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 20px; /* Ajoute du padding pour éviter un débordement */
  box-sizing: border-box;
}

.IT {
  font-size: 3em;
  background: linear-gradient(to bottom, #999999, #2B2B2B);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.skills-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.timeline {
  width: 100%;
  max-width: 1200px; /* Limite la largeur maximale de la timeline */
  margin-bottom: 30px;
  box-sizing: border-box;
}

.skills {
  width: 100%;
  max-width: 1200px; /* Limite la largeur maximale des skills */
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .skills-container {
    padding: 10px;
  }

  .timeline {
    margin-bottom: 20px;
  }

  .card {
    flex: 1 1 100%; /* Les cartes occupent toute la largeur sur les petits écrans */
    max-width: 100%; /* Assure que les cartes n'excèdent pas 100% */
  }

  h1, h2 {
    font-size: 1.5em;
  }

  h3, p {
    font-size: 0.9em;
  }
}

.timeline,
.skills {
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  width: 100%;
  box-sizing: border-box;
}

@media (max-width: 480px) {
  .timeline-container,
  .skills-container {
    padding: 10px;
  }
}

</style>
