<template>
  <div class="card" :class="{ expanded: isExpanded }">
    <div class="top">
      <font-awesome-icon :icon="['fas', icon]" class="icon" />
      <h3>{{ title }}</h3>
    </div>
    <p>
      {{ description }}
    </p>
    <p class="learning">{{bottom}}</p>
  </div>
</template>

<script>
export default {
  name: 'CardComponent',
  props: {
    title: {
      type: String,
      Required: true
    },
    description: {
      type: String,
      Required: true
    },
    bottom: {
      type: String,
      Required: true
    },
    icon:{
      type:String,
      Required:true
    }
  },
  data() {
    return {
      isExpanded: false // État pour savoir si la carte est agrandie
    };
  }

}
</script>

<style scoped>
.top {
  display: flex;
  align-items: center; /* Aligner verticalement les éléments au centre */
  gap: 10px; /* Ajouter un espace entre l'icône et le titre */
  justify-content: center;
}

.icon {
  font-size: 24px; /* Ajuste la taille de l'icône selon tes besoins */
}

.card {
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 300px;
  text-align: center;
  border: solid;
  min-height: 200px;
  border-color: #f39c12;
  border-width: 2px;
}

.card.expanded {
  max-width: 100%;
  max-height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  padding: 50px;
}

</style>
