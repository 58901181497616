<template>
  <div class="timeline">
    <h1 class="title IT">Work Experience</h1>
    <div class="timeline-line"></div>
    <div
      v-for="(event, index) in events"
      :key="index"
      class="timeline-event"
      :class="{'right': index % 2 === 0, 'left': index % 2 !== 0}"
    >
      <div class="timeline-content">
        <h3>{{ event.title }}</h3>
        <p>{{ event.description }}</p>
        <span class="date">{{ event.date }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TimelineComponent',
  props: {
    events: {
      type: Array,
      required: true
    }
  }
};
</script>

<style scoped>
.timeline {
  position: relative;
  width: 100%;
  max-width: 1200px; /* Limite la largeur maximale */
  margin: 20px auto; /* Centre la timeline */
  padding: 20px 0;
}

.title {
  text-align: center;
  margin-bottom: 40px;
  font-size: 2.5em;
  position: relative;
  z-index: 1;
}

/* Ligne centrale de la timeline, démarre après le titre */
.timeline-line {
  position: absolute;
  left: 50%;
  top: 120px; /* Commence après le titre */
  bottom: 20px;
  width: 4px;
  background-color: #333;
  transform: translateX(-50%);
  z-index: 0;
}

.timeline-event {
  position: relative;
  width: 45%;
  padding: 20px;
  box-sizing: border-box;
}

.timeline-event.right {
  left: 55%;
}

.timeline-event.left {
  left: 0;
}

.timeline-content {
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  border: 2px solid #1C1C1C;
  border-color:  #f39c12;
}

.timeline-content::before {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  background-color: #333;
  border-radius: 50%;
}

.timeline-event.right .timeline-content::before {
  left: -5px;
}

.timeline-event.left .timeline-content::before {
  right: -5px;
}

.date {
  font-size: 12px;
  margin-top: 10px;
  display: block;
}

/* Responsive */
@media (max-width: 1024px) {
  .timeline-event {
    width: 80%;
    margin: 0 auto;
    text-align: left;
  }

  .timeline-event.right,
  .timeline-event.left {
    left: 0;
  }

  .timeline-content::before {
    left: -5px;
  }

  .timeline-line {
    display: none; /* Supprimer la ligne sur mobile */
  }
}

@media (max-width: 768px) {
  .timeline-event {
    width: 100%;
    padding: 10px;
  }

  .timeline-content {
    padding: 10px;
  }

  .date {
    font-size: 10px;
  }
}

@media (max-width: 480px) {
  .timeline-event {
    width: 100%;
    padding: 10px;
  }

  .timeline-content {
    padding: 8px;
  }

  .date {
    font-size: 9px;
  }
}
</style>
