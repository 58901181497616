<template>
  <div class="skills-overview">
    <h1 class="skills-title IT" >Skills Overview</h1>

    <div class="skills-timeline">
      <div class="skills-timeline-item" v-for="(section, index) in skills" :key="index">
        <h3 class="skills-section-title">{{ section.title }}</h3>
        <ul class="skills-list">
          <li v-for="(item, idx) in section.items" :key="idx" class="skills-list-item">
            <span class="skills-item-name">{{ item.name }}</span>
            <div class="skills-progress-bar">
              <div class="skills-progress" :style="{ width: item.level + '%' }"></div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SkillsComponent',
  data() {
    return {
      skills: [
        {
          title: 'Languages Mastered',
          items: [
            { name: 'French', level: 100 },
            { name: 'English', level: 85 },
            { name: 'Spanish', level: 25 },
            { name: 'Korean', level: 10 }
          ]
        },
        {
          title: 'Programming Languages',
          items: [
            { name: 'Web', level: 90 },
            { name: 'Node.js', level: 80 },
            { name: 'Java', level: 75 },
            { name: 'C++', level: 60 },
          ]
        },
        {
          title: 'Programming Tools',
          items: [
            { name: 'Git', level: 90 },
            { name: 'Jira', level: 75 },
            { name: 'VSCode', level: 70 }            
          ]
        }
      ]
    };
  }
};
</script>

<style scoped>
.skills-overview {
  padding: 20px; /* Réduit le padding pour les écrans mobiles */
  margin-top: 20px; /* Réduit l'espace entre le Timeline et le SkillsComponent */
  max-width: 100%; /* Permet au composant de s'étendre sur toute la largeur disponible */
}

.skills-title {
  font-size: 2.5em;
  text-align: center;
  margin-bottom: 20px; /* Réduit l'espace en bas pour les écrans plus petits */
  word-wrap: break-word; /* Empêche le débordement du titre */
}

.skills-timeline {
  font-size: 0.8em;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 80%; /* Prend toute la largeur disponible */
  margin: auto;
}

.skills-timeline-item {
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  text-align: left;
}

.skills-section-title {
  font-size: 1.8em;
  margin-bottom: 10px;
}

.skills-list {
  list-style: none;
  padding: 0;
}

.skills-list-item {
  font-size: 1.2em;
  margin-bottom: 15px;
}

.skills-progress-bar {
  background-color: #444444;
  border-radius: 10px;
  height: 8px;
  margin-top: 5px;
  width: 100%;
}

.skills-progress {
  background-color: #f39c12;
  height: 100%;
  border-radius: 10px;
}
</style>
