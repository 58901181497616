<template>
  <header class="header">
    <div class="content">
      <div class="left">
        <a href="mailto:antoine.saintlouis@gmail.com" class="email">
          antoine.saintlouis@gmail.com
        </a>
        <button @click="toggleDark()" class="toggle-button">
          <span v-if="isDark">🌙</span>
          <span v-else>☀️</span>
        </button>
      </div>
      <div class="right">
        <a href="https://www.linkedin.com/in/antoine-st-louis/" target="_blank" class="social-link">LinkedIn</a>
        <span class="separator">|</span>
        <a href="https://github.com/Ant-sai" target="_blank" class="social-link">GitHub</a>
      </div>
    </div>
  </header>
</template>

<script setup>
import { useDark, useToggle } from "@vueuse/core";

const isDark = useDark({
  selector: '#app',
  attribute: "class",
  valueLight: 'light',
  valueDark: 'dark'
});
const toggleDark = useToggle(isDark);
</script>

<script>
export default {
  name: 'HeaderComponent'
}
</script>

<style scoped>
.header {
  width: 100%;
  padding: 20px 0;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.left, .right {
  display: flex;
  align-items: center;
}

.email, .social-link {
  color: #999999;
  text-decoration: none;
  font-size: 1.2em; 
}

.email:hover, .social-link:hover {
  color: #16171d; 
}

.dark .email:hover, .dark .social-link:hover {
  color: #ffffff; 
}

.separator {
  margin: 0 20px;
  color: #999999;
  font-size: 2em;
}

.toggle-button {
  background-color: #444;
  color: #ffffff;
  border: none;
  border-radius: 15px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 1em;
  margin-left: 15px;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
}

.toggle-button:hover {
  background-color: #666;
}

.toggle-button:focus {
  outline: none;
}

.toggle-button span {
  display: flex;
  align-items: center;
}

/* Styles responsives */
@media (max-width: 768px) {
  .content {
    flex-direction: column;
    align-items: flex-start; 
  }

  .left, .right {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 10px; 
  }

  .email {
    font-size: 1em; 
    margin-bottom: 10px; 
  }

  .social-link {
    font-size: 1.2em;
    margin-bottom: 5px;
  }

  .separator {
    display: none; 
  }

  .toggle-button {
    margin-left: 0;
    margin-top: 10px; 
  }
}

@media (max-width: 480px) {
  .email, .social-link {
    font-size: 0.9em;
  }

  .toggle-button {
    font-size: 0.9em; 
    padding: 5px 8px;
  }
}

</style>
