<template>
    <div class="main">
        <img src="@/assets/pictures/profil.jpg" alt="Profil Image"/>
        <h3>Hi, I'm Antoine</h3>
        <h1 class="IT">IT Engineer</h1>
    </div>
</template>

<script>
export default {
    name: 'HomeComponent'
}
</script>

<style scoped>
h1, h3 {
    margin: 0;
}

h3 {
    font-size: 2em;
}

img {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 20px;
}

.main {
    position: relative; /* Permet au composant de suivre le flux normal de la page */
    left: 50%; /* Centre horizontalement */
    transform: translateX(-50%); /* Corrige le centrage horizontal */
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 100%;
    margin: 40px 0 40px 0; /* Ajoute de l'espace autour du composant pour qu'il soit bien espacé du haut */
    padding: 10px;
}
</style>
    