import { createApp } from 'vue';
import App from './App.vue';

// Importer FontAwesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons'; // Importer toutes les icônes solides
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import './assets/styles/style.css'

// Ajouter les icônes solides à la bibliothèque
library.add(fas);

// Créer l'application Vue
const app = createApp(App);

// Enregistrer le composant FontAwesome globalement
app.component('font-awesome-icon', FontAwesomeIcon);

// Monter l'application
app.mount('#app');
